import React, { Suspense, useCallback, useEffect, useState } from "react";
import "./App.css";
import Nav from "./Components/Nav";

import Mobile from "./Components/Mobile";
import Loading from "./Components/Loading";
import HomeDiscount from "./Components/HomeDiscount";
// Memoized components
const MemoizedNav = React.memo(Nav);
const MemoizedMobile = React.memo(Mobile);
const AllRoutes = React.lazy(() => import("./Routes/AllRoutes"));

function App() {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')||(event.ctrlKey && event.shiftKey && event.key === 'i')) {
        event.preventDefault();
      }
    };

    // Add event listeners when the component mounts
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
    return (
    <>
    <HomeDiscount />
      {window.innerWidth >= 1100 ? <MemoizedNav /> : <MemoizedMobile />}
      <Suspense fallback={<Loading load={true} />}>
        <AllRoutes />
      </Suspense>
    </>
  );
}

export default App;
