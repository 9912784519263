export const GET_QUOTE_REQUEST = "GET_QUOTE_REQUEST";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAILURE = "GET_QUOTE_FAILURE";

export const GET_ALL_COURIER_REQUEST = "GET_ALL_COURIER_REQUEST";
export const GET_ALL_COURIER_SUCCESS = "GET_ALL_COURIER_SUCCESS";
export const GET_ALL_COURIER_FAILURE = "GET_ALL_COURIER_FAILURE";

export const GET_GENERATE_LABEL_REQUEST = "GET_GENERATE_LABEL_REQUEST";
export const GET_GENERATE_LABEL_SUCCESS = "GET_GENERATE_LABEL_SUCCESS";
export const GET_GENERATE_LABEL_FAILURE = "GET_GENERATE_LABEL_FAILURE";