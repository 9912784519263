import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  useToast,
  Text,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import RCG_logo from "../Resources/RCG_logo.png";
import { IoMdMenu } from "react-icons/io";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { PiPhoneCallFill } from "react-icons/pi";
import { AiFillHome, AiTwotoneHeart } from "react-icons/ai";
import { MdCorporateFare } from "react-icons/md";
import { FiLogOut, FiPackage } from "react-icons/fi";
import { FaUser, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { wipeWish } from "../Redux/AppReducer/Action";
import MobileNavCard from "./MobileNavCard";
import { logout } from "../Redux/AuthReducer/Action";
import NavAuth from "./NavAuth";
const Mobile = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: mobileIsOpen,
    onOpen: mobileOnOpen,
    onClose: mobileOnClose,
  } = useDisclosure();
  const btnRef = useRef();
  const {
    isOpen: popupIsOpen,
    onOpen: popuponOpen,
    onClose: popupOnClose,
  } = useDisclosure();
  const handleLogout = () => {
    dispatch(logout(toast, navigate));
    dispatch(wipeWish());
    mobileOnClose();
  };
  const category = [
    {
      name: "CLASSIC",
      link: "/allProducts?category=classic",
    },
    {
      name: "PREMIUM",
      link: "/allProducts?category=premium",
    },
    {
      name: "GIFT",
      link: "/allProducts?category=gift",
    },
    {
      name: "WEDDING",
      link: "/allProducts?category=wedding",
    },
    {
      name: "PARTYWARE",
      link: "/allProducts?category=partywear",
    },
    {
      name: "CASUAL",
      link: "/allProducts?category=casual",
    },
    {
      name: "CATERING",
      link: "/allProducts?category=catering",
    },
  ];
  const handleLoginCheck = (point) => {
    if (token === "") {
      toast({
        title: "Please login first",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
      popuponOpen();
    } else {
      navigate(`/${point}`);
    }
  };
  return (
    <Box
      zIndex={"999"}
      position={"sticky"}
      top={"0px"}
      hidden={window.location.pathname.includes("/admin")}
    >
      <NavAuth isOpen={popupIsOpen} onClose={popupOnClose} />
      <Flex
        color={"white"}
        backgroundColor={"#20295a"}
        gap={"30px"}
        h="40px"
        justifyContent={"space-between"}
      >
        <Button ref={btnRef} colorScheme="#20295a" onClick={mobileOnOpen}>
          <IoMdMenu size="26px" />
        </Button>
        <Image id={"logo"} src={RCG_logo} h={"50px"} alt="logo" />
      </Flex>
      <Drawer
        isOpen={mobileIsOpen}
        placement="left"
        onClose={mobileOnClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor={"#6a5df9"} size={"10px"}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Box fontSize={"50px"} color={"white"} gap={"3px"}>
              <Center>
                <BsPersonCircle />
              </Center>
              <Center>
                <Text
                  cursor={"pointer"}
                  fontSize={"16px"}
                  onClick={
                    user.name === undefined
                      ? () => {
                          popuponOpen();
                        }
                      : null
                  }
                >
                  {user.name === undefined ? "LOGIN" : user.name}
                </Text>
              </Center>
            </Box>
          </DrawerHeader>

          <DrawerBody>
            <Flex
              h={window.innerHeight - 130}
              direction={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <MobileNavCard
                  link={"/"}
                  icon={AiFillHome}
                  text={"Home"}
                  close={mobileOnClose}
                />
                <Accordion
                  allowToggle
                  mb={"10px"}
                  border={"none"}
                  color={"white"}
                  borderBottom={"1px solid white"}
                >
                  <AccordionItem border={"none"}>
                    <h2>
                      <AccordionButton>
                        <Flex gap={"20px"} as="span" flex="1" textAlign="left">
                          <Center>
                            <BiSolidCategoryAlt />
                          </Center>

                          <Text>Category</Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {category.map(({ name, link }, i) => {
                        return (
                          <Link to={link} key={i}>
                            <Box
                              p={"10px"}
                              color={"white"}
                              fontSize={"14px"}
                              cursor={"pointer"}
                              key={i}
                              onClick={mobileOnClose}
                              marginBottom={2}
                              textAlign={"left"}
                              borderBottom={"1px solid white"}
                            >
                              {name}
                            </Box>
                          </Link>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <MobileNavCard
                  link={"/corporate"}
                  icon={MdCorporateFare}
                  text={"BULK ORDER"}
                  close={mobileOnClose}
                />
                <Box onClick={() => handleLoginCheck("orders")}>
                  <MobileNavCard
                    icon={FiPackage}
                    text={"My Orders"}
                    close={mobileOnClose}
                    seperator={false}
                  />
                </Box>

                <Box onClick={() => handleLoginCheck("cart")}>
                  <MobileNavCard
                    icon={FaShoppingCart}
                    text={"My Cart"}
                    close={mobileOnClose}
                    seperator={false}
                  />
                </Box>
                <Box onClick={() => handleLoginCheck("wishlist")}>
                  <MobileNavCard
                    icon={AiTwotoneHeart}
                    text={"My Wishlist"}
                    close={mobileOnClose}
                    seperator={false}
                  />
                </Box>
                {token !== "" ? (
                  <Box onClick={() => handleLoginCheck("profile")}>
                    <MobileNavCard
                      icon={FaUser}
                      text={"My Account"}
                      close={mobileOnClose}
                    />
                  </Box>
                ) : null}

                <MobileNavCard
                  link={"/"}
                  text={"Help Centre"}
                  close={mobileOnClose}
                  seperator={false}
                />
                <MobileNavCard
                  link={"/"}
                  text={"Privacy Policies"}
                  close={mobileOnClose}
                  seperator={false}
                />
                <MobileNavCard
                  link={"/"}
                  text={"Payment Policies"}
                  close={mobileOnClose}
                  seperator={false}
                />
                <MobileNavCard
                  link={"/"}
                  text={"Safety Policies"}
                  close={mobileOnClose}
                  seperator={false}
                />
                <MobileNavCard
                  link={"/"}
                  text={"Confidentiality Policies"}
                  close={mobileOnClose}
                />
              </Box>
              <Box>
                <Link to={"/contact"}>
                  <Flex
                    p={"10px"}
                    pl={"17px"}
                    color={"white"}
                    borderBottom={
                      user.name === undefined ? "none" : "1px solid white"
                    }
                    fontSize={"14px"}
                    cursor={"pointer"}
                    onClick={mobileOnClose}
                    marginBottom={2}
                    textAlign={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"17px"}
                  >
                    <Center fontSize={"17px"}>
                      {" "}
                      <PiPhoneCallFill />{" "}
                    </Center>
                    <Text>CONTACT US</Text>
                  </Flex>
                </Link>
                {user.name !== undefined ? (
                  <Flex
                    onClick={handleLogout}
                    color={"white"}
                    p={"10px 0px"}
                    borderBottom={"1px solid white"}
                    fontSize={"14px"}
                    cursor={"pointer"}
                    marginBottom={2}
                    textAlign={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"17px"}
                  >
                    <Center fontSize={"17px"} ml={"-15px"}>
                      <FiLogOut />
                    </Center>
                    <Text textAlign={"left"}>LOGOUT</Text>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Mobile;
