import React, { useCallback, useEffect, useState } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";
import { setDiscount } from "../Redux/AppReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const HomeDiscount = () => {
  const user = useSelector((store) => store.auth.user);
  const discount = useSelector((store) => store.app.discount);
  const [today, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const updateDate = useCallback(() => {
    setDate(new Date());
  }, []);
  // Remove redundant currentSecond variable
  if (window.innerWidth > 590) {
  }
  useEffect(() => {
    if (window.innerWidth > 590) {
      const timer = setInterval(updateDate, 1000);
      return () => clearInterval(timer);
    }
  }, [updateDate]);

  // Optimize Redux dispatch
  useEffect(() => {
    dispatch(setDiscount(discount));
  }, [dispatch, discount]);

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();

  const locale = "en";
  const date = `${day} ${month},${year}`;
  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    second: "2-digit",
  });
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      hidden={
        user.administration === "manager" ||
        user.administration === "admin" ||
        user.administration === "data operator" ||
        user.administration === "marketing manager"
          ? true
          : false
      }
      id={"sale"}
      h={"40px"}
      pt={"10px"}
      pb={"10px"}
      bgColor={"#d32f2e"}
    >
      <Center w={["70%", "70%", "85%", "85%"]}>
        <Text
          ml={window.innerWidth <= 590 ? "" : "20%"}
          fontSize={[10, 12, 14, 16]}
          fontWeight={"bold"}
          color={"#fffc00"}
        >
          {discount?.text}
        </Text>
      </Center>
      <Center hidden={window.innerWidth <= 590}>
        <Text
          fontSize={[10, 12, 14, 16]}
          textAlign={"right"}
          fontWeight={"bold"}
          color={"#fffc00"}
        >
          {date} {time}
        </Text>
      </Center>
    </Flex>
  );
};

export default HomeDiscount;
