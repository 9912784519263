import {
  Box,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  InputLeftAddon,
  useToast,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { HiSquares2X2 } from "react-icons/hi2";
import { BiPackage, BiLogOutCircle } from "react-icons/bi";
import React, { useEffect, useRef, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import RCG_logo from "../Resources/RCG_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { FaUser, FaShoppingCart } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FiSearch } from "react-icons/fi";
import { AiTwotoneHeart } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import "./Nav.css";
import { useDispatch, useSelector } from "react-redux";
import { logout, sendOTP, signin, signup } from "../Redux/AuthReducer/Action";
import axios from "axios";
import { getCart, getWish, wipeWish } from "../Redux/AppReducer/Action";
import check from "../Resources/check.png";
import OtpInput from "react-otp-input";
import NavAuth from "./NavAuth";

const Nav = () => {
  const [load, setLoad] = useState(false);
  const category = [
    {
      name: "HOME",
      link: "/",
    },
    {
      name: "CLASSIC",
      link: "/allProducts?category=classic",
    },
    {
      name: "PREMIUM",
      link: "/allProducts?category=premium",
    },
    {
      name: "GIFT",
      link: "/allProducts?category=gift",
    },
    {
      name: "WEDDING",
      link: "/allProducts?category=wedding",
    },
    {
      name: "PARTYWEAR",
      link: "/allProducts?category=partywear",
    },
    {
      name: "CASUAL",
      link: "/allProducts?category=casual",
    },
    {
      name: "COMBO",
      link: "/allProducts?category=combo",
    },
    {
      name: "BULK ORDER",
      link: "/corporate",
    },
    {
      name: "CONTACT US",
      link: "/contact",
    },
  ];
  const email = useRef(null);
  const password = useRef(null);

  const loginState = useSelector((store) => store.app.loginForm);
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  const loginload = useSelector((store) => store.auth.isLoading);
  const otp = useSelector((store) => store.auth.otp);
  const wish = useSelector((store) => store.app.wish);
  const cart = useSelector((store) => store.app.cart);
  const allProducts = useSelector((store) => store.app.allProducts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchBarText, setSearchBarText] = useState("");
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    mobile: "",
    name: "",
  });
  let temp;
  let searchID;
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  useEffect(() => {
    if (token === "" && loginState == true) {
      onOpen();
    }
  }, [loginState]);

  useEffect(() => {
    handleGetCart();
    dispatch(getCart(token));
  }, []);
  if (user.name !== undefined) {
    temp = user.name.split(" ")[0].toUpperCase();
    temp = temp.split("");
    if (temp.length > 10) {
      temp = temp.splice(0, 10);
      console.log(temp);
      temp = temp.join();
      temp = temp + "...";
    } else {
      temp = temp.join("");
    }
  }
  const handleLogout = () => {
    dispatch(logout(toast, navigate));
    dispatch(wipeWish());
  };
  const handleCart = () => {
    if (token === "") {
      toast({
        title: "Please login first",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
      onOpen();
    } else {
      navigate("/cart");
    }
  };
  const handleGetCart = () => {
    if (
      token !== "" &&
      window.location.href == "/" &&
      !window.location.href.includes("admin")
    ) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res.data.data);
        });
    }
  };

  
  const handleDeleteWish = (skuID) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/wish/delete/${skuID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast({
          title: res.data.msg,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        dispatch(getWish(token));
      });
  };
  const handleAddCart = (skuID) => {
    const data = allProducts.filter((el) => el.skuID === skuID);
    const payload = {
      title: data[0].title,
      price: data[0].price,
      skuID: data[0].skuID,
      discount: data[0].discount,
      quantity: 1,
    };
    if (token === "") {
      dispatch(loginState(toast));
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/cart/create`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast({
            title: res.data.msg,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          handleGetCart();
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const handleSearch = (e) => {
    try {
      let text = e.target.value;
      setSearchBarText(text);
      clearTimeout(searchID);
      searchID = setTimeout(() => {
        if (text !== "") {
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/product/search?search=${text}`
            )
            .then((res) => {
              setSearchData(res.data.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setSearchData([]);
        }
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchClick = (skuID) => {
    setSearchData([]);
    setSearchBarText("");
    navigate(`/allProducts/single/${skuID}`);
  };
  return (
    <Box id="nav" hidden={window.location.pathname.includes("/admin")}>
      <Box h={"70px"} bgColor={"white"}>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Center>
            <Input
              textIndent={"7px"}
              value={searchBarText}
              placeholder="Search"
              w={"700px"}
              mt={"15px"}
              p={"5px 0px"}
              onChange={(e) => handleSearch(e)}
              shadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
            />
            <Box position={"relative"} ml={"-30px"} mt={"17px"}>
              <Center>
                <FiSearch />
              </Center>
            </Box>
          </Center>
        </Flex>
      </Box>
      <Center>
        <Box
          ml={"10px"}
          w={"700px"}
          position={"fixed"}
          top={110}
          bgColor={"white"}
          borderRadius={"7px"}
          hidden={searchData.length <= 0}
        >
          {searchData?.map((ele, i) => {
            return (
              <Flex
                key={i}
                mb={"10px"}
                p={"5px"}
                gap={"5px"}
                cursor={"pointer"}
                onClick={() => handleSearchClick(ele.skuID)}
              >
                <Image
                  h={"50px"}
                  src={`${process.env.REACT_APP_BACKEND_URL}/image?name=${ele.skuID}-1.jpg`}
                />
                <Center>
                  <Text fontSize={"14px"}>{ele.title}</Text>
                </Center>
              </Flex>
            );
          })}
          <Flex
            direction={"row-reverse"}
            p={"0px 5px"}
            onClick={() => {
              navigate(`/search?query=${searchBarText}`);
              setSearchBarText("");
              setSearchData([]);
            }}
          >
            <Text cursor={"pointer"}>See More</Text>
          </Flex>
        </Box>
      </Center>
      <NavAuth
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        h={"42px"}
        bgColor={"#20295a"}
        color={"white"}
        justifyContent={"space-between"}
      >
        <Image
          htmlWidth={"auto"}
          htmlHeight={"100px"}
          id={"logo"}
          src={RCG_logo}
          loading="eager"
          title={"logo"}
          alt={"logo"}
          mt={"-30px"}
          ml={"100px"}
        />
        <Flex
          columns={[3, 5, 5, 10]}
          gap={["15px", "15px", "15px", "25px"]}
          cursor={"pointer"}
          fontSize={["12px", "12px", "12px", "12px", "15px"]}
        >
          {category.map(({ name, link }, i) => {
            return (
              <Center key={i}>
                <Link to={link}>
                  <Box fontWeight={"bold"} _hover={{ fontWeight: "bold" }}>
                    {name}
                  </Box>
                </Link>
              </Center>
            );
          })}
        </Flex>
        <Flex gap={"15px"} w={"250px"}>
          <Center>
            {user.name !== undefined ? null : (
              <Box
                onClick={onOpen}
                cursor={"pointer"}
                h={"30px"}
                bgColor={"white"}
                fontSize={"14px"}
                p={"5px 20px"}
                color={"#20295a"}
                fontWeight={"bold"}
                letterSpacing={"1px"}
              >
                <Center>{user.name === undefined ? "LOGIN" : temp}</Center>
              </Box>
            )}
            {user.name === undefined ? null : (
              <Menu>
                <MenuButton as={Box} cursor={"pointer"} h={"30px"}>
                  <Box
                    bgColor={"white"}
                    fontSize={"14px"}
                    p={"5px 20px"}
                    color={"#20295a"}
                    fontWeight={"bold"}
                    letterSpacing={"1px"}
                  >
                    <Center>{user.name === undefined ? "LOGIN" : temp}</Center>
                  </Box>
                </MenuButton>
                <MenuList
                  bgColor={"#2874f0"}
                  w={"300px"}
                  border={"0px solid black"}
                  h={"auto"}
                >
                  <Box
                    m={"10px 0px"}
                    fontWeight={"bold"}
                    color={"white"}
                    textAlign={"left"}
                    ml={"13px"}
                  >
                    {user.name === undefined ? "" : user.name.toUpperCase()}
                  </Box>
                  {user.name === undefined ? (
                    <MenuItem
                      onClick={onOpen}
                      mb={"-8px"}
                      borderBottomRightRadius={"5px"}
                      borderBottomLeftRadius={"5px"}
                    >
                      SIGNUP / SIGNIN
                    </MenuItem>
                  ) : (
                    <Box>
                      <Box borderTop={"5px solid #0a093d"} color={"black"}>
                        <Link to={"/allProducts"}>
                          <MenuItem
                            p={"10px 0px"}
                            pl={"10px"}
                            _hover={{ bgColor: "#dadee3" }}
                          >
                            <Flex gap={"10px"}>
                              <Center>
                                <HiSquares2X2 />
                              </Center>
                              <Center>
                                <Text cursor={"pointer"}>All Category</Text>
                              </Center>
                            </Flex>
                          </MenuItem>
                        </Link>
                        <Link to={"/orders"}>
                          <MenuItem
                            p={"10px 0px"}
                            pl={"10px"}
                            _hover={{ bgColor: "#dadee3" }}
                          >
                            <Flex gap={"10px"}>
                              <Center>
                                <BiPackage />
                              </Center>
                              <Center>
                                <Text cursor={"pointer"}>My Orders</Text>
                              </Center>
                            </Flex>
                          </MenuItem>
                        </Link>
                        <Link to={"/cart"}>
                          <MenuItem
                            p={"10px 0px"}
                            pl={"10px"}
                            _hover={{ bgColor: "#dadee3" }}
                          >
                            <Flex gap={"10px"}>
                              <Center>
                                <FaShoppingCart />
                              </Center>
                              <Center>
                                <Text cursor={"pointer"}>My Cart</Text>
                              </Center>
                            </Flex>
                          </MenuItem>
                        </Link>
                        <Link to={"/wishlist"}>
                          <MenuItem
                            p={"10px 0px"}
                            pl={"10px"}
                            _hover={{ bgColor: "#dadee3" }}
                          >
                            <Flex gap={"10px"}>
                              <Center>
                                <AiTwotoneHeart />
                              </Center>
                              <Center>
                                <Text cursor={"pointer"}>My Wishlist</Text>
                              </Center>
                            </Flex>
                          </MenuItem>
                        </Link>
                        <Link to={"/profile"}>
                          <MenuItem
                            p={"10px 0px"}
                            pl={"10px"}
                            _hover={{ bgColor: "#dadee3" }}
                          >
                            <Flex gap={"10px"}>
                              <Center>
                                <FaUser />
                              </Center>
                              <Center>
                                <Text cursor={"pointer"}>Edit Profile</Text>
                              </Center>
                            </Flex>
                          </MenuItem>
                        </Link>
                      </Box>

                      <MenuItem
                        color={"black"}
                        borderTop={"3px solid #0a093d"}
                        p={"10px 0px"}
                        mb={"-8px"}
                        borderBottomLeftRadius={"5px"}
                        borderBottomRightRadius={"5px"}
                        pl={"10px"}
                        _hover={{ bgColor: "#dadee3" }}
                        onClick={handleLogout}
                      >
                        <BiLogOutCircle />
                        &nbsp;&nbsp;Logout
                      </MenuItem>
                    </Box>
                  )}
                </MenuList>
              </Menu>
            )}
          </Center>
          <Center>
            <Menu>
              <MenuButton as={Box} cursor={"pointer"}>
                <AiTwotoneHeart />
              </MenuButton>
              <MenuList>
                <TableContainer>
                  <Table variant="simple">
                    <Thead bgColor={"gray.200"}>
                      <Tr>
                        <Th></Th>
                        <Th>Product Name</Th>
                        <Th>Unit Price</Th>
                        <Th>Stock Status</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {wish?.map((el, i) => {
                        const tempText = `${el.title
                          .split("")
                          .splice(0, 20)
                          .join("")}...`;
                        return (
                          <Tr key={i}>
                            <Td>
                              <Flex gap={"8px"}>
                                <Center>
                                  <Box
                                    cursor={"pointer"}
                                    onClick={() => handleDeleteWish(el.skuID)}
                                  >
                                    <RiDeleteBinLine />
                                  </Box>
                                </Center>
                                <Box w={"50px"}>
                                  <Link to={`/allProducts/single/${el.skuID}`}>
                                    <Image
                                      border={"1px solid gray"}
                                      h={"50px"}
                                      src={`${process.env.REACT_APP_BACKEND_URL}/image?name=${el.skuID}-1.jpg`}
                                    />
                                  </Link>
                                </Box>
                              </Flex>
                            </Td>
                            <Td>
                              <Link to={`/allProducts/single/${el.skuID}`}>
                                <Center>
                                  <Text cursor={"pointer"}>{tempText}</Text>
                                </Center>
                              </Link>
                            </Td>
                            <Td>
                              <Link to={`/allProducts/single/${el.skuID}`}>
                                <Center cursor={"pointer"}>{el.price}</Center>
                              </Link>
                            </Td>
                            <Td>
                              <Link to={`/allProducts/single/${el.skuID}`}>
                                <Center>
                                  {el.quantity <= 0 ? (
                                    <Text color={"red"} cursor={"pointer"}>
                                      Out of Stock
                                    </Text>
                                  ) : (
                                    <Text color={"green"} cursor={"pointer"}>
                                      In Stock
                                    </Text>
                                  )}
                                </Center>
                              </Link>
                            </Td>
                            <Td>
                              {data?.some((ele) => ele.skuID === el.skuID) ? (
                                <Flex>
                                  <Image src={check} />
                                </Flex>
                              ) : (
                                <Button
                                  w={"100px"}
                                  variant={"unstyled"}
                                  bgColor={"green"}
                                  color={"white"}
                                  onClick={() => handleAddCart(el.skuID)}
                                >
                                  Add to Cart
                                </Button>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </MenuList>
            </Menu>
          </Center>
          <Center onClick={handleCart} cursor={"pointer"}>
            <Box>
              <FaShoppingCart />
            </Box>
            <Box
              h={"13px"}
              w={"13px"}
              border={"1px solid #0a093d"}
              position={"relative"}
              borderRadius={"50%"}
              top={"-2"}
              left={"-2"}
              color={"#0a093d"}
              fontSize={"10px"}
              flexWrap={"bold"}
              bg={"white"}
            >
              <Center
                position={"relative"}
                top={"-0.5"}
                left={"0"}
                fontWeight={"bold"}
              >
                {cart?.length || 0}
              </Center>{" "}
            </Box>
          </Center>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Nav;
