export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAILURE = "UPDATE_DISCOUNT_FAILURE";

export const GET_HOME_REQUEST = "GET_HOME_REQUEST";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_FAILURE = "GET_HOME_FAILURE";

export const GET_WISH_REQUEST = "GET_WISH_REQUEST";
export const GET_WISH_SUCCESS = "GET_WISH_SUCCESS";
export const GET_WISH_FAILURE = "GET_WISH_FAILURE";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const ALL_PRODUCTFILTER_SUCCESS = "ALL_PRODUCTFILTER_SUCCESS";

export const WIPE_WISH_SUCCESS = "WIPE_WISH_SUCCESS";

export const BUY_NOW_REQUEST = "BUY_NOW_REQUEST";
export const BUY_NOW_SUCCESS = "BUY_NOW_SUCCESS";

export const ALL_LATEST_PRODUCT_SUCCESS = "ALL_LATEST_PRODUCT_SUCCESS";

export const LOGIN_OPEN = "LOGIN_OPEN";

export const GET_PURCHASE_REQUEST = "GET_PURCHASE_REQUEST";
export const GET_PURCHASE_SUCCESS = "GET_PURCHASE_SUCCESS";
export const GET_PURCHASE_FAILURE = "GET_PURCHASE_FAILURE";

export const GET_SINGLEORDER_SUCCESS = "GET_SINGLEORDER_SUCCESS";

export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";

export const GET_ALLCOUPON_REQUEST = "GET_ALLCOUPON_REQUEST";
export const GET_ALLCOUPON_SUCCESS = "GET_ALLCOUPON_SUCCESS";
export const GET_ALLCOUPON_FAILURE = "GET_ALLCOUPON_FAILURE";

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE = "GET_FEEDBACK_FAILURE";

export const GET_RANDOM_PRODUCT = "GET_RANDOM_PRODUCT";

export const GET_USER_PRODUCT_REQUEST = "GET_USER_PRODUCT_REQUEST";
export const GET_USER_PRODUCT_SUCCESS = "GET_USER_PRODUCT_SUCCESS";
export const GET_USER_PRODUCT_FAILURE = "GET_USER_PRODUCT_FAILURE";

export const OLDURL = "OLDURL";

export const POPUP = "POPUP";

export const UPDATE_USER_LIKESDISLIKES = "UPDATE_USER_LIKESDISLIKES";

export const RECOMENDED_COUPON = "RECOMENDED_COUPON";
export const CLEAR_RECOMENDED_COUPON = "CLEAR_RECOMENDED_COUPON";

export const MAGNIFIER_ON = "MAGNIFIER_ON";
export const MAGNIFIER_OFF = "MAGNIFIER_OFF";

export const APPLIED_COUPON = "APPLIED_COUPON";

export const VARIANT_REQUEST = "VARIANT_REQUEST";
export const VARIANT_SUCCESS = "VARIANT_SUCCESS";

export const GET_USER_SHIP_REQUEST = "GET_USER_SHIP_REQUEST";
export const GET_USER_SHIP_SUCCESS = "GET_USER_SHIP_SUCCESS";
export const GET_USER_SHIP_FAILURE = "GET_USER_SHIP_FAILURE";