import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistedStore } from "./Redux/store";
import { extendTheme } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import AOS from 'aos';
import 'aos/dist/aos.css';
const theme = extendTheme({
  components: {
    Menu: {
      baseStyle: {
        list: {
          ".chakra-menu__divider": {
            borderColor: "black",
          },
        },
      },
    },
    Select: {
      baseStyle: {
        border: "none",
      },
    },
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
    text:""
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
const helmetContext = {};
AOS.init();
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
root.render(
  <HelmetProvider context={helmetContext}>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </HelmetProvider>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
reportWebVitals(SendAnalytics);
