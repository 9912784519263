import {
  Box,
  Button,
  Flex,
  FormControl,
  Center,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { LoginSocialGoogle } from "reactjs-social-login";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP, signin } from "../Redux/AuthReducer/Action";
import { ResetPassword, onPasswordResetOpen } from "./ResetPassword";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const handleOtpChange = (otpValue) => {
    // Check if the length of the OTP is 6
    if (otpValue.length === 6) {
      setOtp(otpValue);
      // You can perform further actions here if needed
    }
  };
  return (
    <Box m={"10px 0px"}>
      <OtpInput
        inputStyle={{ color: "black", height: "20px" }}
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
      />
    </Box>
  );
};
const NavAuth = ({ isOpen, onClose }) => {
  const {
    isOpen: isPasswordResetOpen,
    onOpen: onPasswordResetOpen,
    onClose: onPasswordResetClose,
  } = useDisclosure();
  const handleResetPassword = () => {
    onClose();
    onPasswordResetOpen();
  };
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const loginload = useSelector((store) => store.auth.isLoading);
  const otp = useSelector((store) => store.auth.otp);
  const email = useRef();
  const password = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    mobile: "",
    name: "",
  });
  const handleGoogle = async (e) => {
    const { provider, data } = e;
    const payload = {
      name: data.name,
      email: data.email,
      loginType: provider,
      administration: "",
    };
    dispatch(signin(payload, toast, onClose, navigate, setLoad));
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    let temp = { ...userData, [name]: value };
    setUserData(temp);
  };
  const handleSignIn = () => {
    const payload = {
      email: email.current.value,
      password: password.current.value,
      loginType: "manual",
      administration: "",
    };
    if (email.current.value != "" && password.current.value != "") {
      dispatch(signin(payload, toast, onClose, navigate, setLoad));
    } else {
      toast({
        title: "Fill all fields",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleSignup = (payload) => {
    setLoad(true);
    const emailValidation = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (
      payload.fname === "" &&
      payload.lname === "" &&
      payload.email === "" &&
      payload.password === "" &&
      payload.mobile === "" &&
      payload.name === ""
    ) {
      toast({
        title: "Fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      if (emailValidation.test(payload.email) === false) {
        toast({
          title: "Invalid E-mail",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (payload.password.length < 8) {
        toast({
          title: "password must be more than 8 characters",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        userData.name = userData.fname + " " + userData.lname;
        userData.loginType = "Manual";
        delete userData.fname;
        delete userData.lname;
        userData.token = "";
        const emailPayload = {
          sendTo: userData.email,
          subject: "Account Verification for Rarecombee",
          text: `Dear ${userData.name},
          Thank you for registering on our website, Rarecombee. We appreciate your interest and are excited to have you on board.
          To complete the verification process and fully activate your account, please click on the following link: `,
          url: "https://www.rarecombee.com/verify-your-email?token=",
          outro: `If you have any questions, feel free to reach out to our customer support team. We're here to help.
          Best regards,
          Rarecombee Team`,
        };
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            user: userData,
            email: emailPayload,
            username: true,
            username: false,
          })
          .then((res) => {
            setLoad(false);
            navigate("/verify");
            onClose();
          });
      }
    }
  };
  return (
    <Box>
      <ResetPassword
        isPasswordResetOpen={isPasswordResetOpen}
        onPasswordResetClose={onPasswordResetClose}
      />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList mb="1em">
                <Tab>SIGN IN</Tab>
                <Tab>SIGN UP</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack mt={"-70px"} mx={"auto"} maxW={"lg"} py={12} px={6}>
                    <Box
                      rounded={"lg"}
                      bg={useColorModeValue("white", "gray.700")}
                      boxShadow={"lg"}
                      p={8}
                    >
                      <Stack spacing={4}>
                        <FormControl id="email">
                          <Input
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                e.preventDefault();
                                handleSignIn();
                              }
                            }}
                            ref={email}
                            type="email"
                            placeholder="example@gmail.com"
                            isRequired
                          />
                        </FormControl>
                        <FormControl id="password">
                          <InputGroup>
                            <Input
                              onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                  e.preventDefault();
                                  handleSignIn();
                                }
                              }}
                              ref={password}
                              type={showPassword ? "text" : "password"}
                              isRequired
                              placeholder="Password"
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                _hover={{}}
                                variant={"unstyled"}
                                onClick={() =>
                                  setShowPassword(
                                    (showPassword) => !showPassword
                                  )
                                }
                              >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                        </FormControl>
                        <Flex direction={"row-reverse"}>
                          <Text
                            textAlign={"right"}
                            fontSize={"14px"}
                            fontWeight={"400"}
                            onClick={handleResetPassword}
                            color={"#0a093d"}
                            cursor={"pointer"}
                          >
                            Forgot Password ?
                          </Text>
                        </Flex>

                        <Stack spacing={3} pt={2}>
                          <Button
                            fontSize={"14px"}
                            fontWeight={"600"}
                            isLoading={loginload}
                            onClick={handleSignIn}
                            loadingText="Submitting"
                            size="lg"
                            border={"1px solid blue"}
                            _hover={{
                              bg: "blue.500",
                              color: "white",
                              border: "none",
                            }}
                          >
                            SIGN IN
                          </Button>
                          <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || ""}
                            redirect_uri={process.env.REACT_APP_FRONTEND_URL}
                            scope="openid profile email"
                            onResolve={(e) => {
                              handleGoogle(e)
                            }}
                            onReject={(err) => {
                              console.log(err);
                            }}
                          >
                            <Button
                              fontSize={"14px"}
                              fontWeight={"600"}
                              loadingText="Accessing..."
                              isLoading={load}
                              w={"100%"}
                              size="lg"
                              border={"1px solid blue"}
                              _hover={{
                                bg: "blue.500",
                                color: "white",
                                border: "none",
                              }}
                            >
                              <FcGoogle />
                              &nbsp; Signin with Google
                            </Button>
                          </LoginSocialGoogle>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Flex>
                    <FormControl mr="5%">
                      <Input
                        id="first-name"
                        name="fname"
                        placeholder="First name"
                        isRequired
                        onChange={handleOnChange}
                      />
                    </FormControl>

                    <FormControl>
                      <Input
                        id="last-name"
                        name="lname"
                        placeholder="Last name"
                        isRequired
                        onChange={handleOnChange}
                      />
                    </FormControl>
                  </Flex>
                  <FormControl mt="2%">
                    <Input
                      id="email"
                      name="email"
                      onChange={handleOnChange}
                      type="email"
                      isRequired
                      placeholder="example@gmail.com"
                    />
                  </FormControl>

                  <FormControl>
                    <InputGroup mt="2%">
                      <Input
                        minLength={10}
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        isRequired
                        name="password"
                        onChange={handleOnChange}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword(!showPassword)}
                          variant={"unstyled"}
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {otp?.verificationId == undefined ? (
                      <InputGroup size="sm" mt="2%">
                        <InputLeftAddon
                          bg="gray.50"
                          _dark={{
                            bg: "gray.800",
                          }}
                          color="gray.500"
                          rounded="md"
                        >
                          +91
                        </InputLeftAddon>
                        <Input
                          onChange={handleOnChange}
                          type="tel"
                          name="mobile"
                          placeholder="Phone Number"
                          rounded="md"
                        />
                      </InputGroup>
                    ) : (
                      <VerifyOtp />
                    )}
                  </FormControl>
                  <div id="recaptcha-container"></div>
                  {otp?.verificationId == undefined ? (
                      <Button
                        isLoading={load}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        w={"full"}
                        mt={"10px"}
                        onClick={() => {
                          handleSignup(userData);
                        }}
                        loadingText="Submitting"
                        size="lg"
                        border={"1px solid blue"}
                        _hover={{
                          bg: "blue.500",
                          color: "white",
                          border: "none",
                        }}
                      >
                        SIGNUP
                      </Button>
                    ) : (
                      <Button
                        isLoading={load}
                        fontSize={"14px"}
                        fontWeight={"600"}
                        onClick={() => {
                          dispatch(sendOTP(`+91${userData.mobile}`));
                        }}
                        loadingText="Submitting"
                        size="lg"
                        border={"1px solid blue"}
                        _hover={{
                          bg: "blue.500",
                          color: "white",
                          border: "none",
                        }}
                      >
                        SEND OTP
                      </Button>
                    )}
                  <Box mt={"10px"} onClick={onClose}>
                    <Center>
                      <Text
                        _hover={{ color: "red" }}
                        cursor={"pointer"}
                        fontSize={"14px"}
                        fontWeight={"600"}
                      >
                        Continue without signup/signin
                      </Text>
                    </Center>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NavAuth;
