import * as types from "./ActionTypes";
const initialState = {
  loadQuote:false,
  allCourier: [],
  quote:"",
  label:{},
  isError: false,
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_COURIER_REQUEST: {
      return { ...state, allCourier: [], isError: false };
    }
    case types.GET_ALL_COURIER_SUCCESS: {
      return {
        ...state,
        allCourier: payload,
        isError: false,
      };
    }
    case types.GET_ALL_COURIER_FAILURE: {
      return { ...state, allCourier: [], isError: true };
    }
    case types.GET_QUOTE_REQUEST: {
      return { ...state,loadQuote:true, quote: "", isError: false };
    }
    case types.GET_QUOTE_SUCCESS: {
      return {
        ...state,
        quote: payload,
        isError: false,
        loadQuote:false,
      };
    }
    case types.GET_QUOTE_FAILURE: {
      return { ...state,loadQuote:false, quote: "", isError: true };
    }
    case types.GET_GENERATE_LABEL_REQUEST: {
      return { ...state, label: {}, isError: false };
    }
    case types.GET_GENERATE_LABEL_SUCCESS: {
      return {
        ...state,
        label: payload,
        isError: false,
      };
    }
    case types.GET_GENERATE_LABEL_FAILURE: {
      return { ...state, label: {}, isError: true };
    }
    default:
      return state;
  }
};
