import { applyMiddleware, combineReducers } from "redux";
import { legacy_createStore } from "redux"; // Import legacy_createStore
import { Reducer as AppReducer } from "./AppReducer/Reducer";
import { Reducer as AuthReducer } from "./AuthReducer/Reducer";
import { Reducer as ShipReducer } from "./ShipReducer/Reducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "./enc";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_STATE_ENC,
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
  key: process.env.REACT_APP_PERSIST,
  storage,
  stateReconciler: (inboundState, originalState) => {
    const reconcileState = {};
    if (originalState.app) {
      reconcileState.app = {
        ...inboundState.app,
        popup: originalState.app.popup,
        allLatestProducts: originalState.app.allLatestProducts,
        recomPayload: originalState.app.recomPayload,
        userProduct: originalState.app.userProduct,
      };
    }
    if (originalState.auth) {
      reconcileState.auth = {
        ...inboundState.auth,
        otp: originalState.auth.otp,
      };
    }
    if (originalState.ship) {
      reconcileState.ship = {
        ...inboundState.ship,
        quote: originalState.ship.quote,
        loadQuote:originalState.ship.loadQuote
      };
    }
    return {
      ...inboundState,
      ...reconcileState,
    };
  },
};
const RootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  ship: ShipReducer,
});
const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = legacy_createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
const persistedStore = persistStore(store);
export { store, persistedStore };